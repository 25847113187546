import React, { Component } from "react";
import { Link } from 'react-router-dom';
const Portfolio_image1 = <img src="/assets/images/professionals/entreprise01_440x440.jpg" alt="Professionnels 1" />;
const Portfolio_image2 = <img src="/assets/images/professionals/entreprise02_440x440.jpg" alt="Professionnels 2" />;
const Portfolio_image3 = <img src="/assets/images/professionals/entreprise03_440x440.jpg" alt="Professionnels 3" />;
const Portfolio_image4 = <img src="/assets/images/professionals/entreprise04_440x440.jpg" alt="Professionnels 4" />;
const Portfolio_image5 = <img src="/assets/images/professionals/entreprise05_440x440.jpg" alt="Professionnels 5" />;
const Portfolio_image6 = <img src="/assets/images/professionals/entreprise06_440x440.jpg" alt="Professionnels 6" />;


const PortfolioListContent = [
    {
        image: Portfolio_image1,
        category: '',
        title: 'Les conférences',
        description: 'Description'
    },
    {
        image: Portfolio_image2,
        category: '',
        title: 'Le bien-être en pratique',
        description: 'Description'
    },
    {
        image: Portfolio_image3,
        category: '',
        title: 'Les ateliers créatifs',
        description: 'Description'
    },
    {
        image: Portfolio_image4,
        category: '',
        title: 'L\'environnement',
        description: 'Description'
    },
    {
        image: Portfolio_image5,
        category: '',
        title: 'Le féminin',
        description: 'Description'
    },
    {
        image: Portfolio_image6,
        category: '',
        title: 'La parentalité',
        description: 'Description'
    }
];

class PortfolioProfessionals extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        {/*<div className={`im_portfolio ${styevariation}`}>*/}
                        <div className={`${styevariation}`}>
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    {value.image}
                                </div>
                            </div>
                            {/*<div className="content">*/}
                            {/*    <div className="inner">*/}
                            {/*        <div className="portfolio_heading">*/}
                            {/*            <div className="category_list">*/}
                            {/*                <Link to={`activity-0${index + 1}`}>{value.category}</Link>*/}
                            {/*            </div>*/}
                            {/*            <h4 className="title"><Link to={`activity-0${index + 1}`}>{value.title}</Link></h4>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<Link className="transparent_link" to={`activity-0${index + 1}`}></Link>*/}
                            {/*<a className="transparent_link" href={`activity-0${index + 1}`}></a>*/}
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioProfessionals;